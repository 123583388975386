// =============================
// Imports
// =============================

import _get from 'lodash/get';
import { NextSeo } from 'next-seo';
import Router from 'next/router';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withTranslation } from '../config/i18n';

import storeWrapper from '../store';
import { getConfigHome as getConfigHomeBase } from '../store/actions/ConfigActions';

import PrivateRoute from '../components/containers/privateRoute';
import CustomPage from '../components/layouts/custompage';

import { isNewsletterActive, rootRoute } from '../helpers/config';
import { getGenericDocCoverUrl } from '../helpers/entity/common';
import { getValueByLocale } from '../helpers/i18n';
import { getWebsiteTitle } from '../helpers/misc';
import * as pth from '../helpers/proptypes';

// =============================
// Page
// =============================

class HomePage extends PureComponent {
  static propTypes = {
    config: pth.config.isRequired,
    getConfigHome: PropTypes.func.isRequired,
    homeData: pth.configPage.isRequired,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    isLogged: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    xHost: PropTypes.string.isRequired,
  };

  static getInitialProps = storeWrapper.getInitialPageProps(store => async ({ query }) => {
    await store.dispatch(getConfigHomeBase(_get(query, 'draftToken')));

    return {
      namespacesRequired: ['common', 'pages', 'components', 'errors'],
    };
  });

  componentDidMount() {
    const { config } = this.props;

    if (_get(config, 'customisations.homepage.disableAccess', false)) {
      Router.push(rootRoute(config));
    }
  }

  componentDidUpdate(prevProps) {
    const { isLogged: prevIsLogged } = prevProps;
    const { isLogged, getConfigHome } = this.props;

    if (prevIsLogged !== isLogged) {
      getConfigHome();
    }
  }

  render() {
    const {
      config,
      homeData,
      i18n: { language },
      t,
      xHost,
    } = this.props;

    // Do not show homepage if it's disabled
    if (_get(config, 'customisations.homepage.disableAccess', false)) return null;

    const pageUrl = `https://${xHost}`;

    const pageTitle = getWebsiteTitle(
      config,
      getValueByLocale(_get(homeData, 'metaTitles', []), language),
    );
    const pageDescription = getValueByLocale(
      _get(homeData, 'metaDescriptions', []) || [],
      language,
    );

    const pageImage = getGenericDocCoverUrl(
      { image: _get(homeData, 'seoPreview', {}) },
      'original',
    );
    // NOTE: We do not know the width & height of the image here, maybe we'll need to specify it...
    const pageImages = pageImage
      ? [
        {
          url: pageImage,
        },
      ]
      : [];

    return (
      <PrivateRoute>
        <NextSeo
          title={pageTitle}
          description={pageDescription}
          canonical={pageUrl}
          openGraph={{
            type: 'website',
            url: pageUrl,
            title: pageTitle,
            description: pageDescription,
            images: pageImages,
          }}
          noIndex={!_get(homeData, 'enableSeo', false)}
        />
        <CustomPage
          data={homeData}
          t={t}
          newsletterActive={isNewsletterActive(config)}
        />
      </PrivateRoute>
    );
  }
}

function mapStateToProps({ core, user, config }) {
  return {
    homeData: config.home.data,
    isLogged: user.isLogged,
    xHost: core.serverContext.xHost,
  };
}

export default compose(
  withTranslation(['pages']),
  connect(mapStateToProps, {
    getConfigHome: getConfigHomeBase,
  }),
)(HomePage);
